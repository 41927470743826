// takes a userId parameter from the route and fetches the user's stats from the database. It then displays the stats in a table.
// Has a logout button at the bottom

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../resources/AuthContext';
import LoginModal from '../components/LoginModal';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import Logo from '../components/Logo';

const Stats = ({setShowNavText}) => {
    setShowNavText(false);

    const { userId } = useParams();
    const [loading, setLoading] = useState(true);

    const { logout, hasUser, userGames } = useAuth();

    const [showLogin, setShowLogin] = useState(false);

    const [bestTime, setBestTime] = useState(0);
    const [averageTime, setAverageTime] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [streak, setStreak] = useState(0);
    const [bestStreak, setBestStreak] = useState(0);


    const getAccuracy = (games) => {
        let total = 0;
        let correct = 0;
        games.forEach(game => {
            total += game.result.length;
            correct += game.result.filter(res => res[0] === 'correct').length;
        });

        const acc = (correct / total * 100).toFixed(0);
        return `${acc}%`;
    }

    const getAverageTime = (games) => {
        // get the average time of all games that are all correct
        let total = 0;
        games.forEach(game => {
            if (game.result.every(res => res[0] === 'correct')) {
                total += game.time;
            }
        }
        );
        const time = (total / games.length).toFixed(0);
        const stringTime = `${Math.floor(time / 60)}:${time % 60 < 10 ? '0' : ''}${time % 60}`;
        return stringTime;

    }

    const getBestTime = (games) => {
        // get the best time on a game that is all correct
        let bestTime = 0;
        games.forEach(game => {
            if (game.result.every(res => res[0] === 'correct')) {
                if (game.time < bestTime || bestTime === 0) {
                    bestTime = game.time;
                }
            }
        });
        // format as '0:00' string
        const stringBestTime = `${Math.floor(bestTime / 60)}:${bestTime % 60 < 10 ? '0' : ''}${bestTime % 60}`;
        return stringBestTime;
    }

    const getStreak = (games) => {
        // count the number of days in a row that the user has played
        // the games are NOT sorted by date
        // first, get all the dates
        const dates = games.map(game => game.games.date);
        // sort the dates
        dates.sort((a, b) => new Date(b) - new Date(a));
        console.log(dates);
        

        // count up the CURRENT streak and the BEST streak
        let bestStreak = 0;
        let currentStreak = 0; // will be 0 if the user has not played YESTERDAY
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        console.log('Today:', today);
        console.log('Yesterday:', yesterday);

        // dates are sorted in descending order

        for (let i = 0; i < dates.length; i++) {
            const date = new Date(dates[i]);
            console.log('Date:', date);
            if (date.toDateString() === yesterday.toDateString()) {
                currentStreak++;
                if (currentStreak > bestStreak) {
                    bestStreak = currentStreak;
                }
                yesterday.setDate(yesterday.getDate() - 1);
            }
            else {
                break;
            }
        }

        return [currentStreak, bestStreak];
    }

    useEffect(() => {
        if (userGames.length > 0) {
            setBestTime(getBestTime(userGames));
            setAverageTime(getAverageTime(userGames));
            setAccuracy(getAccuracy(userGames));
            const [streak, bestStreak] = getStreak(userGames);
            setStreak(streak);
            setBestStreak(bestStreak);

        }
        setLoading(false);
    }, [userGames]);



    return (
        <div className='stats'>
            {hasUser ?
                <div>
                    {/* <div className='centered'>
                        <h1>Stats page coming soon!</h1>
                    </div> */}
                    <br/>
                    <div className='centered'>
                        <Logo />
                    </div>
                    <br/>
                    <div className='divider'/>
                    {/* <div className='centered'>
                        <h1>Your Stats</h1>
                    </div> */}
                    <div className='centered stats-container'>
                        <div className='stat-display'>
                            <div className='stat-row'>
                                <h1> {bestTime} </h1>
                                <h2>Best Time</h2>
                            </div>
                            <div className='stat-row'>
                                <h1> {averageTime} </h1>
                                <h2>Average Time</h2>
                            </div>
                            <div className='stat-row'>
                                <h1> {accuracy} </h1>
                                <h2>Accuracy</h2>
                            </div>
                            <div className='stat-row'>
                                <h1> {streak} </h1>
                                <h2>Current Streak</h2>
                            </div>
                            <div className='stat-row'>
                                <h1> {bestStreak} </h1>
                                <h2>Best Streak</h2>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='divider' />

                    <div className='small-break' />
                    <div className='centered'>
                        <button className='standard-button primary' onClick={() => window.location.href = '/archive/'}>
                            <CalendarViewMonthIcon className='button-icon'/>
                            Play Past Games
                        </button>
                    </div>
                    <br/>
                    <div className='centered'>
                        <button className='standard-button' onClick={logout}>Logout</button>
                    </div>
                    <br/>

                </div>
                :
                <div>
                    <h1>Not Authorized</h1>
                    <p>You must be logged in to view this page.</p>
                </div>
        }

        </div>
    );
}

export default Stats;
