// Dashboard for internal use only
import React, { useEffect, useState } from 'react';
import supabase from '../resources/Supabase';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ data }) => {
    // Parse the data object into labels and data arrays
    const labels = Object.keys(data).sort();  // Sorting to ensure dates are in order
    const chartData = labels.map(label => data[label]);

    const chartConfig = {
        labels: labels,
        datasets: [
            {
                label: 'Count per Day',
                data: chartData,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.1,
            },
        ],
    };

    return <Line data={chartConfig} />;
};



const countEventsOnDates = (dates) => {
    const date_counts = {};
    // dates are currently in UTC time. convert them to EST before counting
    const est_dates = dates.map(dateStr => {
        let date = new Date(dateStr); // Convert string to Date object
        date.setHours(date.getHours() - 4); // Subtract 4 hours
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        let day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Return the date part as a string in YYYY-MM-DD format
    });

    // sort the dates
    est_dates.sort();

    est_dates.forEach(date => {
        const date_str = date.toString().split('T')[0];
        if (date_str in date_counts) {
            date_counts[date_str] += 1;
        } else {
            date_counts[date_str] = 1;
        }
    });

    // get the cumulative counts by counting the number of games played on or before that date
    const cumulative_counts = {};
    let cumulative_count = 0;
    for (const date in date_counts) {
        cumulative_count += date_counts[date];
        cumulative_counts[date] = cumulative_count;
    }



    return [date_counts, cumulative_counts];
};

const getLastEntry = (obj) => {
    console.log('keys', Object.keys(obj));
    return obj[Object.keys(obj)[Object.keys(obj).length-1]];
}

const Dashboard = () => {

    const [dateCounts, setDateCounts] = useState({});
    const [todayCount, setTodayCount] = useState(0);
    const [shareCounts, setShareCounts] = useState({});
    const [todayShareCount, setTodayShareCount] = useState(0);
    const [cumulativePlays, setCumulativePlays] = useState({});
    const [cumulativeShares, setCumulativeShares] = useState({});

    const [showCumulative, setShowCumulative] = useState(false);

    const getData = async () => {
        // get the created_at in player_games and temp_games
        // extract the date and count the number of games played on that date
        // return the data

        const { data: player_games, error: player_games_error } = await supabase
            .from('player_games')
            .select('created_at');
        
        const { data: temp_games, error: temp_games_error } = await supabase
            .from('temp_games')
            .select('created_at');

        if (player_games_error || temp_games_error) {
            console.error(player_games_error, temp_games_error);
            return;
        }

        const player_games_dates = player_games.map(game => game.created_at);
        const temp_games_dates = temp_games.map(game => game.created_at);

        const dates = [...player_games_dates, ...temp_games_dates];

        const [date_counts, cumulative_counts] = countEventsOnDates(dates);

        setDateCounts(date_counts);
        console.log('play counts ', date_counts);
        // get the LAST entry in the date_counts object
        setTodayCount(getLastEntry(date_counts));
        setCumulativePlays(cumulative_counts);

    }

    const getShareCounts = async () => {
        const { data: shares, error: shares_error } = await supabase
            .from('share_actions')
            .select('created_at');

        if (shares_error) {
            console.error(shares_error);
            return;
        }

        const share_dates = shares.map(share => share.created_at);
        const [share_counts, cumulative_counts] = countEventsOnDates(share_dates);

        console.log('share counts', share_counts);
        setShareCounts(share_counts);
        // last entry in the share_counts object
        setTodayShareCount(getLastEntry(share_counts));
        setCumulativeShares(cumulative_counts);
    }

    useEffect(() => {
        getData();
        getShareCounts();
    }, []);


    return (
        <div>
            <h1>Dashboard</h1>
            {showCumulative ? <button onClick={() => setShowCumulative(false)}>Show Daily Counts</button> : <button onClick={() => setShowCumulative(true)}>Show Cumulative Counts</button>}
            {showCumulative ? <LineChart data={cumulativePlays} /> : <LineChart data={dateCounts} />}
            <h4>Today's count: {todayCount}</h4>
            <h3>Shares</h3>
            {showCumulative ? <LineChart data={cumulativeShares} /> : <LineChart data={shareCounts} />}
            <h4>Today's shares: {todayShareCount}</h4>
        </div>
    )
}

export default Dashboard;