// This is a scene that shows the archive of past games.
// For a range of dates, it shows a calendar (MUI's DateCalendar) that allows the user to pick a game from a past day
// The calendar will show a check icon on the days that the user has already played
// The calendar will not show games from dates that do not exist in the 'games' table in supabase

import React, { useState, useEffect } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useAuth } from '../resources/AuthContext';

import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const Archive = () => {
    const {userId, userGames, userDataLoaded} = useAuth();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedGame, setSelectedGame] = useState(null);
    const baseDate = dayjs('2024-06-13');

    const [highlightedDays, setHighlightedDays] = useState([]);

    const [initialValue, setInitialValue] = useState(dayjs(new Date()));
    const [isLoading, setIsLoading] = useState(true);
    const requestAbortController = React.useRef(null);
    const [userGameSelected, setUserGameSelected] = useState(null);

    const fetchHighlightedDays = async (date) => {
        if (userGames.length > 0) {
            const dates = userGames.map(game => new Date(game.games.date));
            console.log('dates', dates);
            // filter dates to keep only those from the same month as initialDate
            const filteredDates = dates.filter(date => date.getMonth() === initialValue.month() && date.getFullYear() === initialValue.year());
            console.log('filteredDates', filteredDates);
            // convert dates into their int day of month
            const datesInt = filteredDates.map(date => date.getDate() + 1);
            setHighlightedDays(datesInt);
            setIsLoading(false);
        }
        else if (userDataLoaded) {
            // if there are no user games, then we can stop loading
            // wait a second before stopping loading
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }
        else {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }

    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
      
        const isSelected =
          !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
      
        return (
          <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '✅' : undefined}
          >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
          </Badge>
        );
      }

    const handleMonthChange = (date) => {
        if (requestAbortController.current) {
          // make sure that you are aborting useless requests
          // because it is possible to switch between months pretty quickly
          requestAbortController.current.abort();
        }
    
        setIsLoading(true);
        setInitialValue(dayjs(date).endOf('month').isBefore(dayjs()) ? dayjs(date).endOf('month') : dayjs());
        setHighlightedDays([]);
    };

    useEffect(() => {
        // fetchGames();
        if (selectedDate){
            fetchHighlightedDays(selectedDate);
        }
    }, [selectedDate, userGames, userDataLoaded, initialValue]);

    const handleGameClick = (date) => {
        // get the $D of the selected date
        const dateObject = new Date(date);
        // get days since base date
        const daysSinceBase = dayjs(dateObject).diff(baseDate, 'day');
        console.log('Days since base:', daysSinceBase);
        // if the daysSinceBase is a gameId that is in userGames, then set that userGame as the userGameSelected
        if (userGames.length > 0) {
            const userGame = userGames.find(game => game.game === daysSinceBase);
            console.log('User game:', userGame);
            setUserGameSelected(userGame);
        }
        setSelectedGame(daysSinceBase);
        
    }

    return (
        <div className='scene'>
            <h1>Archive</h1>
            {isLoading && <CircularProgress />}
            {!isLoading && 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        defaultValue={initialValue}
                        minDate={baseDate}
                        maxDate={dayjs(new Date())}
                        loading={isLoading}
                        onMonthChange={handleMonthChange}
                        renderLoading={() => <DayCalendarSkeleton />}
                        // on change, call handleGameClick for the $D of the selected date
                        onChange={(date) => {
                            handleGameClick(date);
                        }}
                        slots={{
                            day: ServerDay,
                        }}
                        slotProps={{
                            day: {
                                highlightedDays,
                            }
                        }}
                        views={['day']} 
                    />
                </LocalizationProvider>
            }

            <div className='centered'>
                {selectedGame && !userGameSelected &&
                    <div>
                        <h2>{selectedGame.date}</h2>
                        <button className='standard-button' onClick={() => {
                            window.location.href = `/game/${selectedGame}`;
                        }}>
                            <PlayCircleIcon className='button-icon'/>
                            Play Game
                        </button>
                    </div>
                }
                {userGameSelected &&
                    <div>
                        <h2>{userGameSelected.date}</h2>
                        <h2>{selectedGame.date}</h2>
                        <button className='standard-button' onClick={() => {
                            window.location.href = `/game/${selectedGame}`;
                        }}>
                            <FactCheckIcon className='button-icon'/>
                            View Results
                        </button>
                    </div>
                }
            </div>
        </div>
    );
}

export default Archive;


