

// component
import React from 'react';
import BoxRow from './BoxRow';

const Logo = () => {
    return (
    <div>
        <div className="boxes">                
            {[['correct', 'correct']].map((result, index) => (
                // if the result is empty, skip it, otherwise show the box
                result[0] !== 'empty' && <BoxRow key={index} states={result} />
            ))}
        </div>
        <h2 className='title-card-header' onClick={() => window.location.href = '/'}>
        Sneaky Link
        </h2>
    </div>
    );

}

export default Logo;