import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({body, show, setShow, modalStyle={}}) => {

    const closeModal = () => {
        setShow(false);
    }

    // add listeners that will close the modal when the user clicks outside of it or presses the escape key
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClose = (e) => {
        e.stopPropagation();
        closeModal();
    }

    const suppressClicks = (e) => {
        e.stopPropagation();
    }


    return (
        <div className='modal-container' onClick={(e) => suppressClicks(e)}>        
            {show &&
                <div>

                    <div className='modal' style={modalStyle}>

                        <div className='nav-header'>
                            <button 
                                className='centered-icon-button minimal close'
                                onClick={handleClose}
                            >
                                <CloseIcon/>
                            </button>
                        </div>
                        <div className='modal-content'>
                            {body}
                        </div>

                    </div>

                    <div className='modal-backdrop' onClick={handleClose}/>

                </div>
            }
        </div>
    );
};

export default Modal;