// import supabase from './Supabase';

const cleanString = (str) => {
    const remove_list = '!"#$%\'()*+,-./:;<=>?@[\\]^_`{|}~';
    let cleaned = str;
    for (let i = 0; i < remove_list.length; i++) {
        cleaned = cleaned.replace(remove_list[i], '');
    }
    return cleaned.toLowerCase().trim();
}

const editDistance = (str1, str2) => {
    const m = str1.length;
    const n = str2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) {
        for (let j = 0; j <= n; j++) {
            if (i === 0) {
                dp[i][j] = j;
            } else if (j === 0) {
                dp[i][j] = i;
            } else if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = 1 + Math.min(dp[i][j - 1], dp[i - 1][j], dp[i - 1][j - 1]);
            }
        }
    }
    return dp[m][n];
}


const checkAnswer = (answer_in, answers) => {
    const allowableError = 0.2;
    const answer = cleanString(answer_in);
    for (let i = 0; i < answers.length; i++) {
        if (answer === cleanString(answers[i])) {
            return true;
        }
        if (editDistance(answer, cleanString(answers[i])) < allowableError * answers[i].length) {
            return true;
        }
    }
    return false;

}


const checkSubmission = (question, answer1, answer2) => {
    console.log(question);
    // return a tuple of correct1, correct2
    const correct1 = checkAnswer(answer1, question.alt_before_answer);
    const correct2 = checkAnswer(answer2, question.alt_after_answer);

    // true -> 'correct', false -> 'incorrect'
    const correct1_str = correct1 ? 'correct' : 'incorrect';
    const correct2_str = correct2 ? 'correct' : 'incorrect';

    return [correct1_str, correct2_str];
}

export { checkSubmission };