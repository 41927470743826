// component that shows questions and answers, in 'priority' order, for a given game ID

import React, { Component, useState, useEffect } from 'react';
import supabase from '../resources/Supabase';

const Answers = ({gameId, date}) => {

    const [questions, setQuestions] = useState([]);

    const fetchData = async () => {
        if (!gameId) {
            return;
        }
        const { data, error } = await supabase
            .from('questions')
            .select('*')
            .eq('game', gameId)
            .order('priority', {ascending: true});
        
        if (error) {
            console.error('Error fetching answers:', error);
        }
        if (data) {
            console.log(data);
            setQuestions(data);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <h1 className='answer-key-header'>Answer Key</h1>
            <h3 className='title-card-text'>{date}</h3>
            <p className='title-card-text'>Puzzle No. {gameId}</p>
            <div className='answer-key'>
                {questions.map((question, i) => {
                    return (
                        <div>
                        <div className='divider'/>

                        <div key={question.id} className='question-answer-row'>
                            <div className='question-answer'>
                                <h2 className='answer-key-q-header'>{question.before}</h2>
                                <h3 className='answer-key-a-header'>{question.before_answer}</h3>
                            </div>
                            <div className='question-answer'>
                                <h2 className='answer-key-q-header'>{question.after}</h2>
                                <h3 className='answer-key-a-header'>{question.after_answer}</h3>
                            </div>
                        </div>
                        </div>

                    );
                })}
            </div>
            <div className='divider'/>
        </div>
    );
}

export default Answers;
