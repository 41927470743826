// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import supabase from './Supabase'
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null)
    const hasUser = session ? true : false;
    const [userId, setUserId] = useState(null);
    const [ip, setIp] = useState(null);
    const [userGames, setUserGames] = useState([]);
    const [unsavedGames, setUnsavedGames] = useState([]);
    const [userDataLoaded, setUserDataLoaded] = useState(false);

    useEffect(() => {
      const fetchIpInfo = async () => {
        try {
          const response = await axios.get('https://ipinfo.io/json?token=f0683cb48b48a0');
          setIp(response.data.ip);
          console.log('IP information:', response.data);

        } catch (error) {
          console.error('Error fetching the IP information:', error);
        }
      };
  
      fetchIpInfo();
    }, []);

    const getTempGames = async () => {
        console.log('Getting temp games');
        const { data: tempGames, error } = await supabase
            .from('temp_games')
            .select('*, games(*)')
            .eq('ip_address', ip)
        if (error) {
            console.error('Error getting temp games:', error)
            return null
        }
        if (tempGames) {
            // console.log('Temp games:', tempGames)
            return tempGames
        }
    }


    const getUserGames = async () => {
        console.log('Getting user games');
        if (userId) {
            const { data: userGames, error } = await supabase
                .from('player_games')
                .select('*, games(*)')
                .eq('user_id', userId)
            if (error) {
                console.error('Error getting user games:', error)
                return null
            }
            if (userGames) {
                // console.log('User games:', userGames)
                return userGames
            }
        }
    }

    const updateAllUserGames = async () => {
        // const tempGames = await getTempGames();
        const tempGames = null;
        const userGames = await getUserGames();

        // console.log('user ID:', userId);

        // console.log('Temp games:', tempGames);
        // console.log('User games:', userGames);

        // combine the two arrays and get the unique games, then set userGames to that
        if (tempGames && userGames) {
            const combinedGames = userGames.concat(tempGames)
            const uniqueGames = combinedGames.filter((game, index, self) =>
                index === self.findIndex((t) => (
                    t.game === game.game
                ))
            )
            setUserGames(uniqueGames);
        }

        else if (userGames) {
            console.log('no temp games were found - ')
            const combinedGames = userGames;
            const uniqueGames = combinedGames.filter((game, index, self) =>
                index === self.findIndex((t) => (
                    t.game === game.game
                ))
            )
            setUserGames(uniqueGames);
        }

        // If there are temp games that are unsaved, we want to save them to the user's games
        if (tempGames){
            const unsavedGames = tempGames.filter((tempGame) => {
                if (userGames) {
                    return !userGames.some((userGame) => userGame.game === tempGame.game)
                } else {
                    return null
                }
            })

            console.log('Unsaved games:', unsavedGames)
            if (unsavedGames.length > 0) {
                setUnsavedGames(unsavedGames)
            }

        }
        console.log('user games have been updated.')
        setUserDataLoaded(true);
    }


        

    const userSetup = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        if (user) {
            setUserId(user.id)
        }
    }

    const logout = async () => {
        console.log('logging out')
        await supabase.auth.signOut()
        window.location.href = '/';
    }


    const saveUnsavedGames = async () => {
        
        if (unsavedGames) {
            // check if any of the temp games are NOT already saved in userGames

            // console.log('Temp games:', tempGames)
            // save each temp game to the user
            unsavedGames.forEach(async (game) => {
                const payload = {
                    user_id: userId,
                    game: game.game,
                    time: game.time,
                    created_at: game.created_at,
                    result: game.result
                }
                console.log('saving temp game:', payload)
                const { data2, error2 } = await supabase
                    .from('player_games')
                    .upsert(payload)
                if (error2) {
                    console.error('Error updating temp game:', error2)
                }
                if (data2) {
                    console.log('Temp game updated:', data2)
                }
            })
        }

    }


    useEffect(() => {
        if (ip) {
            updateAllUserGames();
        }
    }, [ip, userId])

    useEffect(() => {
        userSetup();
        if (userId) {
            saveUnsavedGames();
        }
    }, [userId])

    useEffect(() => {
        console.log('User Games:', userGames)
    }, [userGames])


    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])


    return (
        <AuthContext.Provider value={{ session, setSession, hasUser, userId, logout, ip, userGames, updateAllUserGames, userDataLoaded }}>
            {children}
        </AuthContext.Provider>
    );

};

export const useAuth = () => useContext(AuthContext);
