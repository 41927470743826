import React, {useEffect, useState} from 'react';

const Help = ({close}) => {

    const [showAnswer, setShowAnswer] = useState(false);

    const questions = {
        'before': `Classic book beginning with "call me Ishmael"`,
        'after': 'Former Vice President known for his policy decisions post 9/11'
    }

    const answers = {
        'before': 'Moby Dick',
        'after': 'Dick Cheney'
    }


    useEffect(() => {
        const toggle = () => setShowAnswer(prevState => !prevState);
        const intervalId = setInterval(toggle, 4000);

        return () => clearInterval(intervalId);
    }, []);
  

    return (
        <div className='guide' style={{height:'405px'}}>
            <div className='guide-header'>
                <h2 className='guide-title'>
                    How to Play
                </h2>
                <p>
                    Based on the two hints provided, you come up with answers that connect together! Here's an example:
                </p>
            </div>
            <div className="question-container-help">
                    <div className="question-help">
                        <div className="question-column">
                            <h3>{questions.before}</h3>
                        </div>
                        <div className="question-column">
                            <h3>{questions.after}</h3>
                        </div>
                    </div>

                    <div className='centered'>
                        {showAnswer &&
                            <h3 className='example-text'>Moby <span className="highlight">Dick</span> Cheney</h3>
                        }
                        {!showAnswer && 
                            <div className='answer-bar' >
                                <input
                                    className='animated-answer-left question-input'
                                    type="text"
                                    value={answers.before}
                                    disabled
                                    style={{marginTop:'0px'}}
                                />
                                <input
                                    className='animated-answer-right question-input'
                                    type="text"
                                    value={answers.after}
                                    disabled
                                    style={{marginTop:'0px'}}
                                />
                            </div>
                            }
                    

                    </div>
            </div>
            <br />
            <div className='centered'>
                <button className='gray-button' onClick={close}>PLAY NOW</button>
            </div>
            
        </div>



    )



}


export default Help;