// navbar - also give me the css.
// this should have a hamburger icon on one side (import MenuIcon from '@mui/icons-material/Menu';)
// on the other side, it has a stats button and a settings button
// import BarChartIcon from '@mui/icons-material/BarChart';
// import SettingsIcon from '@mui/icons-material/Settings';
// in the middle, it has a header saying "Before and After"
// it has a 2px D9D9D9 border on the bottom

import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import Modal from './Modal';
import Help from './Help';
import { useAuth } from '../resources/AuthContext';
import LoginModal from './LoginModal';

const Navbar = ({showNavText}) => {
    const [showHelp, setShowHelp] = useState(false);
    const {userId, hasUser} = useAuth();
    const [showSignUp, setShowSignUp] = useState(false);

    const goToHome = () => {
        window.location.href = '/';
    }

    const showStats = () => {
        console.log('show stats was clicked: is there a user?')
        console.log(hasUser);
        if (!hasUser) {
            setShowSignUp(true);
        }
        else {
            window.location.href = '/stats/' + userId;
        }
    }

    useEffect(() => {
        console.log('has user? ', hasUser);
        if (!hasUser && window.location.pathname === '/') {
            setShowHelp(true);
        }
        else {
            setShowHelp(false);
        }

    }, [hasUser]);

    return (
        <div>
            <div className="navbar">
                <div className="navbar-left">
                    <button className='minimal-button' onClick={() => setShowHelp(true)}><HelpIcon /></button>

                    {/* <MenuIcon /> */}
                    {/* <div className='gap'/> */}
                </div>
                {showNavText && <h2 className='header-name' onClick={goToHome}>Sneaky Link</h2>}
                <div className="navbar-right">
                    <button className='minimal-button' onClick={showStats}><BarChartIcon /></button>
                </div>
            </div>
            {<Modal onClose={() => setShowHelp(false)} body={<Help close={() => setShowHelp(false)}/>} show={showHelp} setShow={setShowHelp} />}
            <LoginModal show={showSignUp} setShow={setShowSignUp} successRoute={'/stats/' + userId} />
        </div>
    );
}

export default Navbar;

