// Just takes in an array of states and returns that many boxes

import React from 'react';
import Box from './Box';

const BoxRow = ({ states }) => {
    return (
        <div className="box-container">
            {states.map((state, i) => <Box key={i} state={state} />)}
        </div>
    );
}

export default BoxRow;