// This is the main scene for the game.
// It controls the clock and shows boxes scoring correct/incorrect answers.

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../resources/Supabase';
import Clock from '../components/Clock';
import BoxRow from '../components/BoxRow';
import Question from '../components/Question';
import Share from '../components/Share';
import Modal from '../components/Modal';
import LoginForm from '../components/LoginForm';
import Answers from '../components/Answers';
import { useAuth } from '../resources/AuthContext';
import BarChartIcon from '@mui/icons-material/BarChart';
import LoginModal from '../components/LoginModal';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CheckIcon from '@mui/icons-material/Check';
import QuizIcon from '@mui/icons-material/Quiz';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import {checkSubmission} from '../resources/AnswerChecker';
import Logo from '../components/Logo';

const GameScene = ({setShowNavText}) => {
    const { id } = useParams();
    const [clockRunning, setClockRunning] = useState(true);
    const { hasUser, userId, session, ip, userGames } = useAuth();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [gameDone, setGameDone] = useState(false);
    const [time, setTime] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [game_id, setGameId] = useState('');
    const [timeOverride, setTimeOverride] = useState(null);
    const [showAnswers, setShowAnswers] = useState(false);
    const [results, setResults] = useState([]); // list of lists, with the correct/incorrect/partial stored for each question
    const date = new Date().toLocaleDateString();
    // const date = new Date('2024-07-01').toLocaleDateString();
    console.log('date:', date);
    console.log('full local time: ', new Date());
    const [formattedDate, setFormattedDate] = useState('');
    // const daysFromStart = Math.ceil((new Date() - new Date('2024-06-13')) / (1000 * 60 * 60 * 24));
    const daysFromStart = Math.ceil((new Date(date) - new Date('2024-06-13')) / (1000 * 60 * 60 * 24)+1);
    console.log('days from start:', daysFromStart);

    useEffect(() => {
        if (game_id){
            fetchQuestions();
        }
    }, [game_id]);

    // useEffect to set game_id. If there is a parameter in the URL, use that; if not, use the date and look up the game_id
    useEffect(() => {
        if (id){
            const idInt = parseInt(id);

            if (idInt > daysFromStart || idInt < 1){
                setGameId(daysFromStart);
            }
            else {
                setGameId(idInt);
            }
        }
        else {
            // get the game_id from the date
            supabase
                .from('games')
                .select('*')
                .eq('date', date)
                .then(data => {
                    if (data.data.length > 0){
                        if (data.data[0].game_id > daysFromStart || data.data[0].game_id < 1){
                            setGameId(daysFromStart);
                        }
                        else {
                            setGameId(data.data[0].game_id);
                        }
                    }
                })
        }
    }, [id]);


    // initialize results with ['empty', 'empty'] for each question
    useEffect(() => {
        if (results.length === 0 && questions.length > 0){
            setResults(questions.map(() => ['empty', 'empty']));
        }
    }, [questions]);

    useEffect(() => {
        if (gameStarted){
            setShowNavText(true);
        }
        else {
            setShowNavText(false);
        }
    }, [gameStarted]);

    const gameOver = () => {
        setClockRunning(false)
        setGameDone(true);
    }

    const fetchResults = () => {
        // check and see if the user has played already. Get the result and time. Show them, and set game over.
        if (!game_id || !userGames){
            return;
        }
        // lookup the game_id in the userGames array

        const game = userGames.find(g => g.game === game_id);



        if (game && game.result.length > 0){
            setResults(game.result);
            setGameDone(true);
            setGameStarted(true);
            setTimeOverride(game.time);
            setClockRunning(false);
        }
    }


    // when gameOver, store the results in the database
    useEffect(() => {
        if (gameDone && results[results.length - 1][0] !== 'empty'){
            storeResult();
        }
    }, [gameDone]);

    const storeResult = async () => {
        // upsert results to supabase 
        // 'player_games' schema: user_id, game_id, time, result
        // convert time to seconds

        // TODO: check if the user has already played this game. if so, escape.
        const hasUserPlayed = userGames.find(game => game.game === game_id);
        if (hasUserPlayed){
            return;
        }

        const timeArray = time.split(':');
        const timeInSeconds = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);

        if (hasUser){
            const gameData = {
                user_id: userId,
                game: game_id,
                time: timeInSeconds,
                result: results
            }
            supabase
                .from('player_games')
                .insert(gameData)
                .then(data => {
                    console.log('data:', data);
                });
        }
        else {
            // store the session instead
            const gameData = {
                game: game_id,
                time: timeInSeconds,
                result: results,
                ip_address: ip
            }

            supabase
                .from('temp_games')
                .insert(gameData)
                .then(data => {
                    console.log('data:', data);
                });
        }
    }


    const gameStart = () => {
        setClockRunning(true);
        setGameDone(false);
        setQuestionIndex(0);
        setGameStarted(true);
    }

    const insertResults = (result1, result2) => {
        const newResults = [...results];
        newResults[questionIndex] = [result1, result2];
        setResults(newResults);
        questionIndex < questions.length - 1 ? setQuestionIndex(questionIndex + 1) : gameOver();
    }

    const checkAnswer = (question, answer1, answer2) => {
        const res = checkSubmission(question, answer1, answer2);
        insertResults(res[0], res[1]);            
    }
    

    const fetchQuestions = () => {
        supabase
            .from('games')
            .select('*, questions(id, before, after, priority, alt_before_answer, alt_after_answer)')
            .eq('game_id',game_id)
            .then(data => {
                // order the questions by priority
                data.data[0].questions.sort((a, b) => a.priority - b.priority);
                setQuestions(data.data[0].questions);
                // get date, format it and set formattedDate
                // new Date().toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
                const [year, month, day] = data.data[0].date.split('-');

                // Create a new Date object using the components (month is 0-indexed)
                const date = new Date(year, month - 1, day);

                // Define options for the toLocaleDateString method
                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                // Format the date to the desired format
                setFormattedDate(date.toLocaleDateString('en-US', options));     
            })
    }
    
    const handleViewStats = () => {
        if (hasUser){
            window.location.href = `/stats/${userId}`;
        }
        else {
            setShowSignUp(true);
        }
    }

    const handleArchive = () => {
        window.location.href = `/archive/`;
    }


    // useEffect(() => {
    //     if (hasUser){
    //         console.log('User is logged in');
    //         console.log(userId);
    //         setShowSignUp(false);
    //     }
    // }, [hasUser]);

    useEffect(() => {
        if (questions.length > 0 && questionIndex < questions.length){
            setCurrentQuestion(questions[questionIndex].id);
        }
    }, [questionIndex, questions]);

    useEffect(() => {
        fetchResults();
    }, [game_id, userGames]);
    
    return (
        <div className="game-scene">
            {!gameStarted &&
                <div className="game-start centered`">
                    <br />
                    <br />
                    <br />

                    {/* <img src='/bandalogo.svg' alt='B&A Logo' className='logo' /> */}
                    <Logo />
                    
                    <h4 className='title-card-text'>Test your knowledge of pop culture, history, literature, and more with this daily trivia puzzle!</h4>
                    <br />
                    
                    <h3 className='title-card-text'>{formattedDate}</h3>
                    <p className='title-card-text'>Puzzle No. {game_id}</p>

                    <br />
                    <br />
                    <button className='standard-button start-game' onClick={gameStart}>Start Game</button>

                </div>
            }
            {gameStarted && 
                <div className="game">
                <Clock start={clockRunning} setTimeString={setTime} timeOverride={timeOverride}/>
                {results && results.length > 0 && 
                    <div>
                        <div className="boxes">                
                            {results.map((result, index) => (
                                // if the result is empty, skip it, otherwise show the box
                                result[0] !== 'empty' && <BoxRow key={index} states={result} />
                            ))}

                        </div>
                    </div>}

                {!gameDone && 
                    <Question question={questions[questionIndex]} checkAnswer={checkAnswer} attemptsRemaining={attemptsRemaining} />
                }
                {results && results.length > 0 && 
                    <div>
                        <div className='small-break' />
                        <div className="boxes">                
                            {results.map((result, index) => (
                                // if the result is NOT empty, skip it, otherwise show the box
                                result[0] === 'empty' && <BoxRow key={index} states={result} />
                            ))}
                        </div> 
                    </div>
                }
                </div>     
            }

            {gameDone &&
                <div className="game-over">
                    <h1>Game Over!</h1>
                    <button className='standard-button primary' onClick={handleArchive}>
                        <CalendarViewMonthIcon className='button-icon'/>
                        Play More Games
                    </button>
                    <br />
                    {
                        <button className='standard-button' onClick={() => setShowAnswers(true)}>
                            <CheckIcon className='button-icon'/>
                            View Answers
                        </button>
                    }
                    <br />
                    <Share results={results} time={time} pastGameId={id}  />
                    <br />
                    {!hasUser &&
                        <button className='standard-button' onClick={() => setShowSignUp(true)}>
                            <BarChartIcon className='button-icon'/>
                            Log In to Save Stats
                        </button>
                    }
                    {hasUser &&
                        <button className='standard-button' onClick={handleViewStats}>
                            <BarChartIcon className='button-icon'/>
                            View Your Stats
                        </button>
                    }
                    
                    <br />
                    {/* <button className='standard-button' onClick={
                        <LightbulbIcon className='button-icon'/>
                        Submit a Question
                    </button> 
                    <br />
                    */}
                </div>
            }
            {showSignUp &&
                <LoginModal show={showSignUp} setShow={setShowSignUp} />
            }
            {showAnswers &&
                <Modal onClose={() => setShowAnswers(false)} 
                    body={<Answers gameId={game_id} date={formattedDate} />}
                    show={showAnswers} 
                    setShow={setShowAnswers} 
                    modalStyle={{top: '300px'}}
                />
            }

        </div>
    );
};

export default GameScene;
