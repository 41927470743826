// component that takes in a 'results' array and creates a link to make a text message

import React, { useEffect, useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import supabase from '../resources/Supabase';
import { useAuth } from '../resources/AuthContext';

const Share = ({ results, time, pastGameId }) => {
    const [message, setMessage] = useState('');
    const todayDate = new Date();
    // const [textUrl, setTextUrl] = useState('');
    const [copied, setCopied] = useState(false);
    const {userId} = useAuth();

    const createMessage = () => {
        // if the result is 'correct', 🟩
        // if the result is 'incorrect', 🟥
        // if the result is 'partial', 🟨
        const emojis = {
            'correct': '🟩',
            'incorrect': '🟥',
            'partial': '🟨',
            'empty': '⬜'
        }

        const message = results.map((result, i) => {
            return `${emojis[result[0]]} ${emojis[result[1]]}`;
        }).join('\n');

        const dateString = todayDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });

        // add date and time to the message
        // if pastGameId, append /game/pastGameId to the message
        const base = `Sneaky Link - (${dateString})\n${time}\n${message}\nsneaky-link.com`;
        if (pastGameId) {
            setMessage(`${base}/game/${pastGameId}`);
        }
        else {
            setMessage(base);
        }
    }

    const logShare = async () => {
        // log in supabase 'share_actions' that this happened. 
        // only need to upsert user ID
        // create variable to hold payload
        let payload = {}

        if (userId) {
            payload = {
                user_id: userId,
            }        
        }

        const { data, error } = await supabase
            .from('share_actions')
            .upsert(payload)
        
        if (error) {
            console.error('Error logging share:', error);
        }
        if (data) {
            console.log('Share logged:', data);
        }
        
    }

    const copyMessage = () => {
        // copy message to clipboard
        console.log(message);
        navigator.clipboard.writeText(message);
        setCopied(true);
        logShare();
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    useEffect(() => {
        createMessage();
    }, [results, time]);

    // useEffect(() => {
    //     console.log(message);
    //     const url = `sms:?body=${encodeURIComponent(message)}`;
    // }, [message]);

    return (
        <div className="share-container">
            <button onClick={copyMessage} className="standard-button">
                {!copied ? <ShareIcon className='button-icon'/> : <DownloadDoneIcon className='button-icon'/>}
                {!copied ? 'Share Results' : 'Copied!'}
            </button>
        </div>
    );

}

export default Share;