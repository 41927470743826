import React, { useEffect, useState } from 'react';
import LoginForm from './LoginForm';
import Modal from './Modal';

const LoginModal = ({ show, setShow, successRoute='/'}) => {
    // console.log('success route at login form: ' + successRoute)
    return (
        <Modal body={
            <div className='' style={{height:'400px'}}>
                <h3>Create a free account to save your score!</h3>
                {/* <p> success route: {successRoute}</p> */}
                <LoginForm />
            </div>
        } show={show} setShow={setShow} successRoute={successRoute} />
    );
}

export default LoginModal;
    