import React from 'react'
import { useAuth } from '../resources/AuthContext'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import supabase from '../resources/Supabase'

const LoginForm = ({successRoute='/'}) => {
    const { session, setSession, hasUser, userId } = useAuth();

    // when login is successful, redirect to the successRoute
    if (session && hasUser) {
        console.log('redirecting to ' + successRoute);
        window.location.href = successRoute;
    }

    return (
        <div className='scene centered'>
            <div className='standard-page-container'>
                <div className='auth-container'>
                    <Auth 
                        supabaseClient={supabase} 
                        view="sign_up"
                        localization={{
                            variables: {
                            sign_in: {
                                email_label: 'Email',
                                password_label: 'Password',
                                email_input_placeholder: 'Email',
                                password_input_placeholder: 'Password',
                                button_label: 'Log In',
                            },
                            sign_up: {
                                email_label: 'Email',
                                password_label: 'Password',
                                email_input_placeholder: 'Email',
                                password_input_placeholder: 'Password',
                                button_label: 'Create Account',
                            },
                            },
                        }}
                        appearance={{
                            theme: ThemeSupa,
                            variables: {
                            default: {
                                colors: {
                                brand: '#4CC488',
                                brandAccent: '#A8E6CF',
                                },
                            },
                            },
                        }}
                        providers={[]}
                    />
                </div>
            </div>
        </div>
    )

    
}

export default LoginForm;
