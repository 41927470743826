// Basic box that shows a state: it will be "correct", "partial", "incorrect", or "empty"
// This component just takes a state and adds a class to the box to style it accordingly

import React from 'react';

const Box = ({ state }) => {
    return (
        <div className={`box ${state}`}>
        </div>
    );
};

export default Box;