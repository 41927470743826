// Component that has two columns. Each displays a question and has a text box to submit an answer.
// There is a submit button on the bottom, in the middle.
// The component takes in a 'question' object
// The question object has a 'before' and 'after' key, each with a string value - these are the questions
// The component also takes in a 'checkAnswer' function that is called when the submit button is clicked (it passes the two answers as arguments)

import React, { useEffect, useState, useRef } from 'react';

const Question = ({ question, checkAnswer, attemptsRemaining }) => {
    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [showAnswer, setShowAnswer] = useState(false);
    const input1Ref = useRef(null);


    const submit = () => {
        checkAnswer(question, answer1, answer2);
        setAnswer1('');
        setAnswer2('');
    }

    // add a listener that submits on enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // set the first answer box focused
            submit();
            if (input1Ref.current) {
                input1Ref.current.focus();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [answer1, answer2]);

    return (
        <div className="question-container">
            {question &&
                <div className="question">
                    <div className="question-column">
                        <h3>{question.before}</h3>
                        {showAnswer && <h4>{question.answer1}</h4>}
                        {!showAnswer &&
                            <input
                                ref={input1Ref}
                                className='question-input'
                                type="text"
                                value={answer1}
                                onChange={(e) => setAnswer1(e.target.value)}
                                autocomplete="off" 
                                autocorrect="off" 
                                spellcheck="false" 
                                inputmode="text"
                            />
                        }
                    </div>
                    <div className="question-column">
                        <h3>{question.after}</h3>
                        {showAnswer && <h4>{question.answer2}</h4>}
                        {!showAnswer &&
                            <input
                                className='question-input'
                                type="text"
                                value={answer2}
                                onChange={(e) => setAnswer2(e.target.value)}
                                autocomplete="off" 
                                autocorrect="off" 
                                spellcheck="false" 
                                inputmode="text"
                            />
                        }
                    </div>
                </div>
            }
            <div className='question-button-container'>
                <button className='gray-button' onClick={submit}>ENTER</button>
            </div>
        </div>
    );
};

export default Question;

