// component that shows a stop watch (JUST A VERY BASIC COUNTER)
// takes in a bool that starts and stops the clock

import React, { useState, useEffect } from 'react';

const Clock = ({ start, timeOverride, setTimeString }) => {
    const [time, setTime] = useState(0);
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    useEffect(() => {
        if (timeOverride) {
            setTime(timeOverride);
        }
    }, [timeOverride]);

    useEffect(() => {
        let interval;
        if (start) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [start]);

    useEffect(() => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        setMinutes(minutes < 10 ? `0${minutes}` : minutes);
        setSeconds(seconds < 10 ? `0${seconds}` : seconds);
    }, [time]);

    useEffect(() => {
        setTimeString(`${minutes}:${seconds}`);
    }, [minutes, seconds]);

    return (
        <div className="clock">
            <h2>{minutes}:{seconds}</h2>
        </div>
    );
};

export default Clock;
