// make this a router-based app
import React, {useEffect, useState} from 'react';
import GameScene from './scenes/GameScene';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import { AuthProvider } from './resources/AuthContext';
import Stats from './scenes/Stats';
import LoginForm from './components/LoginForm';
import Archive from './scenes/Archive';
import Dashboard from './scenes/Dashboard';

const App = () => {
    const [showNavText, setShowNavText] = useState(true);
    return (
        <div className="App">
            <AuthProvider>
                <BrowserRouter>
                    <Navbar showNavText={showNavText}/>
                    <Routes>
                        <Route path="/" element={<GameScene setShowNavText={setShowNavText}/>} />
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/stats/:userId" element={<Stats setShowNavText={setShowNavText}/>} />
                        <Route path="/game/:id" element={<GameScene setShowNavText={setShowNavText}/>} />
                        <Route path="/archive" element={<Archive/>} />
                        <Route path="/dashboard" element={<Dashboard/>} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;